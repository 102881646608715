var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"border-0"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h3',{staticClass:"mb-0"},[_vm._v("Users")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('el-tooltip',{attrs:{"content":"Add a new User","placement":"top"}},[_c('base-button',{attrs:{"type":"neutral","icon":"","size":"sm"},on:{"click":function($event){return _vm.addUser()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-user-edit"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v("Add")])])],1)],1)],1)],1),_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.tableData}},[_c('el-table-column',{attrs:{"label":"Id","min-width":"100px","prop":"id","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"label":"Username","min-width":"210px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"Email","prop":"email","min-width":"240px","sortable":""}}),_c('el-table-column',{attrs:{"label":"Created At","min-width":"100px","prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"font-weight-bold",attrs:{"href":"#!"}},[_vm._v(_vm._s(row.created_at))])]}}])}),_c('el-table-column',{attrs:{"min-width":"180px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":"Edit","placement":"top"}},[_c('a',{staticClass:"table-action",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Edit product"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(row)}}},[_c('i',{staticClass:"fas fa-user-edit"})])]),_c('el-tooltip',{attrs:{"content":"Delete","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",attrs:{"href":"#!","data-toggle":"tooltip","data-original-title":"Delete product"},on:{"click":function($event){$event.preventDefault();return _vm.onDelete(row)}}},[_c('i',{staticClass:"fas fa-trash"})])])],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }