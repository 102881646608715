<template>
  <div class="bg-transparent">
    <base-header class="pb-6 bg-default shadow">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Bank Submission Dashboard</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col cols="5" lg="6" class="text-right">
        </b-col>
      </b-row>
      <!-- Card stats -->
    </base-header>

    <!--Charts-->
    <b-container fluid class="mt--6 bg-default shadow" >
        <card class="bg-default shadow; border border-white;">
        <form class="needs-validation"
            @submit.prevent="submit">

        <div class="form-row" style="color: white; ">
          <b-col md="5">
          <label><b>Filter Data From Date : </b></label>
              <base-input  name="To"
                              type="date"
                              v-model="model.start_date">
              </base-input>
          </b-col>
          <b-col md="5">
          <label><b>To Date : </b></label>
              <base-input  name="To"
                              type="date"
                              v-model="model.end_date">
              </base-input>
          </b-col>         
          <b-col md="2" align-self="center">
            <base-button size="l" type="primary" @click="changeDateGetSubmissionDetails()" >Search</base-button>
          </b-col>
          &nbsp;<h5 class="h3 mb-0 text-white"><font color="red">( Data Last Sync On :  {{ model.last_update_date }} )</font> </h5> 
              &nbsp;&nbsp;&nbsp;
              <div class="text-right">
                <base-button size="sm" type="primary" @click="updateData()">Sync / Update Data</base-button>
              </div>
        </div>
        </form>
        </card>
        <b-row>
          <b-col xl="12">
          <submission-charts ref="submission_chart" :start-date=model.start_date :end-date=model.end_date />
          
          </b-col>
        </b-row>
        <b-row >
          <b-col xl="12">
            <card type="default" header-classes="bg-transparent" class="border border-white;">
              <b-row align-v="center" slot="header">
              &nbsp;&nbsp;<h5 class="h3 mb-0 text-white">Bank Submission From {{ model.start_date }} To {{ model.end_date }}  
                <!-- <font color="red">( Last Updated On :  {{ model.last_update_date }} )</font> </h5>  --></h5> 
              &nbsp;&nbsp;&nbsp;
              <!-- <div class="text-right">
                <base-button size="sm" type="primary" @click="updateData()">Sync / Update Data</base-button>
              </div> -->
              </b-row>
              <b-row>
                <div class="table-responsive">
                <table class="table table-bordered ">
                  <thead>
                    <tr>
                      <th class="text-white">Bank Name</th>
                      <th class="text-white">Total<br>Submission</th>
                      <th class="text-white">Actual<br>Submission</th>
                      <th class="text-white">Approved</th>
                      <th class="text-white">Reject</th>
                      <th class="text-white">Decline<br>By Client</th>
                      <th class="text-white">Cancel</th>
                      <th class="text-white">Other</th>
                      <th class="text-white">Approval<br>Rate %</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in bank_submission_datas" :key="item.bank_id">
                      <td class="text-white">{{item.bank_name}}</td>
                      <td class="text-white" @click="getSubmissionDetails(item.bank_id, 'ALL', 'TOTAL SUBMISSION')">{{item.total_count}}</td>
                      <td class="text-white" @click="getSubmissionDetails(item.bank_id, 'ACTUAL', 'ACTUAL SUBMISSION')">{{item.total_actual_submission}}</td>
                      <td class="text-white" @click="getSubmissionDetails(item.bank_id, 'APPROVED', 'APPROVED')">{{item.approved_count}}</td>
                      <td class="text-white" @click="getSubmissionDetails(item.bank_id, 'REJECTED', 'REJECT')">{{item.reject_count}}</td>
                      <td class="text-white" @click="getSubmissionDetails(item.bank_id, 'DECLINE', 'DECLINE BY CLIENT')">{{item.decline_by_client_count}}</td>
                      <td class="text-white" @click="getSubmissionDetails(item.bank_id, 'CANCEL', 'CANCEL')">{{item.cancel_count}}</td>
                      <td class="text-white" @click="getSubmissionDetails(item.bank_id, 'OTHER', 'OTHER')">{{item.other_count}}</td>
                      <td class="text-white" >{{item.approval_rate}} %</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-white"> Total : </td>
                      <td class="text-white">{{ bank_submission_summary.total_count }}</td>
                      <td class="text-white">{{ bank_submission_summary.total_actual_submission }}</td>
                      <td class="text-white">{{ bank_submission_summary.total_approved_count}}</td>
                      <td class="text-white">{{ bank_submission_summary.total_reject_count}}</td>
                      <td class="text-white">{{ bank_submission_summary.total_decline_by_client_count}}</td>
                      <td class="text-white">{{ bank_submission_summary.total_cancel_count}}</td>
                      <td class="text-white">{{ bank_submission_summary.total_other_count}}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="text-white">Total Customer : </td>
                      <td class="text-white" colspan="8">{{ bank_submission_summary.total_customer }} </td>
                    </tr>
                    <tr>
                      <td class="text-white">Total SPA Price (RM) : </td>
                      <td class="text-white" colspan="8">{{ bank_submission_summary.total_spa_price }} </td>
                    </tr>
                    <tr>
                      <td class="text-white">Total Loan Amount (RM) : </td>
                      <td class="text-white" colspan="8">{{ bank_submission_summary.total_loan_amount }} </td>
                    </tr>
                  </tfoot>
                </table>
                </div>
                  <div>
                    <br>
                    <h5 class="text-danger"> Note : <br>
                      <i>
                      1. Change the date will submission statistic that based on the date range between "From Date" and "To Date". 
                      </i>
                      <br>
                      <i>
                      2. Click on VALUE in "Total Submission", "Actual Submission", "Approved", "Reject", "Decline By Client", "Cancel", "Other" 
                      will show the submission details DATA in a POP-UP from imortgage2u Portal that been sync.
                      </i>
                      <br>
                      <i>
                      3. By Default Start Date is set to 1 day of the year.
                      </i>
                      <br>
                      <i>
                      4. By Default End Date is set to "TODAY".
                      </i>
                      <br>
                      <i>
                      5. All data will show last Updated date they been sync to imortgage2u Portal.
                      </i>
                      <br>
                      <i>
                      6. All data will be sync every night after 10PM.
                      </i>
                    </h5>
                  </div>
              </b-row>
            </card>
          </b-col>
        </b-row>
       
    </b-container>

    <modal :show.sync="modals.formSubmissionDetails"
      size="xl"  v-bind:hide-footer="true"
      body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template>
        <H2 class="text-center"><b>Summary Details for {{ modals.title }}</b></H2>
        <br>
        <b-container fluid class="mt--6">
        <div>
          <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
            <template slot="header">
              <h3 class="mb-0">Submission Details for {{ modals.title }} </h3>
                <div class="text-right">
                  <base-button size="sm" type="primary" @click="clearModal()">Close</base-button>
                </div>
            </template>
            <div>
              <!-- <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
                <div>
                  <base-input v-model="searchQuery"
                              prepend-icon="fas fa-search"
                              placeholder="Search...">
                  </base-input>
                </div>
              </b-col> -->
        
              <el-table :data="queriedData"
                        row-key="project_id"
                        header-row-class-name="thead-light"
                        @sort-change="sortChange">

                <!-- <div>
                  <base-input v-model="searchQuery"
                              prepend-icon="fas fa-search"
                              placeholder="Search...">
                  </base-input>
                </div> -->

                <el-table-column label="Loan Process ID"
                              min-width="60px"
                              prop="loan_process_id"
                              sortable>
                  <template v-slot="{row}">
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">ID : {{row.loan_process_id}}</span>
                              <br>
                              <span class="font-weight-600 name mb-0 text-sm">Case Date : {{row.loan_case_date}}</span>
                          </b-media-body>
                      </b-media>
                  </template>
                </el-table-column>

                <el-table-column label="Customer Name"
                              min-width="70px"
                              prop="customer_name"
                              sortable>
                  <template v-slot="{row}">
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.customer_name}}</span>
                          </b-media-body>
                      </b-media>
                  </template>
                </el-table-column>

                <el-table-column label="Bank & Status"
                              min-width="70px"
                              prop="process_status"
                              sortable>
                  <template v-slot="{row}">
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">Bank : {{row.bank_name}}</span>
                            <br>
                            <span class="font-weight-600 name mb-0 text-sm">Status: {{row.process_status}}</span>
                          </b-media-body>
                      </b-media>
                  </template>
                </el-table-column>

                
                <el-table-column label="Price & Amount (RM)"
                              min-width="70px"
                              prop="process_status"
                              sortable>
                  <template v-slot="{row}">
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">SPA :{{row.spa_price}}</span>
                              <br>
                              <span class="font-weight-600 name mb-0 text-sm">LOAN : {{row.loan_amount}}</span>
                          </b-media-body>
                      </b-media>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                  <span v-if="selectedRows.length">
                    &nbsp; &nbsp; {{selectedRows.length}} rows selected
                  </span>
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                :current="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
                @change="paginationChanged($event)">
              </base-pagination>
            
            </div>
          </card>
        </div>
        </b-container>
        </template>
      </card>
    </modal>

  </div>
</template>
<script>
  // Charts
  import VueApexCharts from 'vue-apexcharts';
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import { Charts } from "@/components/Charts/config";

  // Components
  import BaseProgress from '@/components/BaseProgress';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';

  import { Table, TableColumn, Select, Option } from 'element-ui';
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios';
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
  import Fuse from 'fuse.js';
  import { Modal } from '@/components';
  import submissionCharts from './SubmissionCharts';

  function randomScalingFactor() {
    return Math.round(Math.random() * 100);
  }

  export default {
    mixins: [ standardPaginationMix, ErrorFunctionMixin ],
    components: {
      Modal,
      "apexchart": VueApexCharts,
      LineChart,
      BarChart,
      BaseProgress,
      RouteBreadCrumb,
      StatsCard,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      "submission-charts" : submissionCharts,
    },
    created()
    {
      var currentDate = getcurrentDate()
      this.model.start_date = '2022-01-01'
      this.model.end_date = currentDate
      this.chartData.start_date = '2022-01-01'
      this.chartData.end_date = currentDate
      this.changeDateGetSubmissionDetails()
    },
    data() {
      return {
      chartData : {
        bank_name: [],
        approved_count: [],
        reject_count:[],
        start_date: null,
        end_date: null,
      },
      modals: {
        title: null,
        formSubmissionDetails: false,
      },
      propsToSearch:
      [
        'loan_process_id',
        'loan_case_date',
        'customer_name',
        'bank_name',
        'process_status',
        'spa_price',
        'loan_amount',
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      bank_submission_summary: {
          total_count: 0,
          total_actual_submission: 0,
          total_approved_count: 0,
          total_reject_count: 0,
          total_decline_by_client_count: 0,
          total_cancel_count: 0,
          total_other_count: 0,
          total_customer: 0,
          total_spa_price: 0.00,
          total_loan_amount: 0.00,
      },
    
      bank_submission_datas : [],
      project_types : [],
       model: {
          project_type_id : 0,
          project_overdue_total_site: 0,
          start_date: null,
          end_date: null,
          checkboxes: 1,
          title: 'CONSOLIDATED',
          radios: {
          radio1: 'CONSOLIDATED',
          radio2: 'HIDE',
           last_updated_date: null,
          }
        },
        overdue_highest: {
          one: 0,
          two: 0,
          three: 0,
          four: 0,
          high_message: null,
        },
        overdue_turn_on: {
          one: 0,
          two: 0,
          three: 0,
          four: 0,
          high_message: null,
          overdue_total_mwp: 0,
        },
        barChartStacked: {
          chartData: {
            labels: ['March', 'April', 'May', 'June', 'July'],
            datasets: [{
              label: 'SN2 Actual',
              backgroundColor: Charts.colors.theme['success'],
              data: [
                1,1.5,1.2,2.9,2.6
              ]
            },
            {
              label: 'SN3 Actual',
              backgroundColor: Charts.colors.theme['primary'],
              data: [
               1.3,2.3,2.8,3.8,4.4
              ]
            }]
          },
          extraOptions: {
            tooltips: {
              mode: 'index',
              intersect: false
            },
            responsive: true,
            scales: {
              xAxes: [{
                stacked: true,
              }],
              yAxes: [{
                stacked: true
              }]
            }
          }
        },
        bigLineChart: {
          allData: [
            [22.2, 6.6, 2.8, 9.0, 2.6, 0.0, 0.9, 2.2, 0.0, 6.3],
            [0, 20, 5, 25, 10, 30, 15, 40, 40]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [
              {
                label: 'Performance',
                data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              }
            ],
            labels: ['March', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
        redBarChart: {
          chartData: {
            labels: ['March', 'April', 'May', 'June', 'July'],
            datasets: [{
              label: 'MWp',
              data: [1.0, 2.3, 2.8, 3.8, 4.4]
            }]
          },
          extraOptions: chartConfigs.blueChartOptions
        },
        seriesProjectOverdue: [],
        ChartOptionsProjectOverdue:
        {
            colors: ['#119ee1', '#0c765a', '#ef8215', '#F43314'],
            chart: {
            width: 380,
            type: 'donut',
            },
            plotOptions: {
              pie: {
              startAngle: -90,
              endAngle: 270,
              },
            },
            labels: ["< 30 Days", "30 - 60 Days", "60 - 90 Days", "> 90 Days"],
            dataLabels: {
            enabled: true
            },
            fill: {
            // type: 'gradient',
            },
            legend: {
            formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
            },
             labels: {
            colors: ['white'],
            useSeriesColors: false
            },
          position: 'bottom'
          },
          title: {
          text: ''
          },
        },
        seriesOverDueTurnOn: [],
        ChartOptionsOverDueTurnOn:
        {
            colors: ['#119ee1', '#0c765a', '#ef8215', '#F43314'],
            chart: {
            width: 380,
            type: 'donut',
            },
            plotOptions: {
              pie: {
              startAngle: -90,
              endAngle: 270,
              },
            },
            labels: ["Project < 1 MWp", "Project 1 - 5 MWp", "Project 5 - 10 MWp", "Project > 10 MWp"],
            dataLabels: {
            enabled: true
            },
            fill: {
            // type: 'gradient',
            },

            legend: {
            formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
            },
            labels: {
            colors: ['white'],
            useSeriesColors: false
            },
          position: 'bottom'
          },
          title: {
          text: ''
          },
        },
        project_turn_on: {
          total_est : 0,
          total_turn_on: 0,
        },
        seriesProjectTurnOn: [],
        ChartOptionsProjectTurnOn:
        {
            colors: ['#119ee1', '#0c765a', '#ef8215',],
            chart: {
            width: 380,
            type: 'donut',
            },
            plotOptions: {
              pie: {
              startAngle: -90,
              endAngle: 270,
              },
            },
            labels: ["Pending", "Completed", "Overdue",],
            dataLabels: {
            enabled: true
            },
            fill: {
            // type: 'gradient',
            },

            legend: {
            formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
            },
            labels: {
            colors: ['white'],
            useSeriesColors: false
            },
          position: 'bottom'
          },
          title: {
          text: ''
          },
        },
        document_summary: {
          document_total : 0,
          document_completed: 0,
        },
        seriesDocumentSummary: [],
        ChartOptionsDocumentSummary:
        {
            colors: ['#119ee1', '#0c765a', '#ef8215',],
            chart: {
            width: 380,
            type: 'donut',
            },
            plotOptions: {
              pie: {
              startAngle: -90,
              endAngle: 270,
              },
            },
            labels: ["Pending", "Completed", "Overdue",],
            dataLabels: {
            enabled: true
            },
            fill: {
            // type: 'gradient',
            },

            legend: {
            formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
            },
            labels: {
            colors: ['white'],
            useSeriesColors: false
            },
          position: 'bottom'
          },
          title: {
          text: ''
          },
        },
        document_overdue: {
          one: 0,
          two: 0,
          three: 0,
          four: 0,
          message: null,
          total_overdue: 0,
        },
        seriesDocumentOverdue: [],
        ChartOptionsDocumentOverdue:
        {
            colors: ['#119ee1', '#0c765a', '#ef8215', '#F43314'],
            chart: {
            width: 380,
            type: 'donut',
            },
            plotOptions: {
              pie: {
              startAngle: -90,
              endAngle: 270,
              },
            },
            labels: ["< 30 Days", "30 - 60 Days", "60 - 90 Days", "> 90 Days"],
            dataLabels: {
            enabled: true
            },
            fill: {
            // type: 'gradient',
            },

            legend: {
            formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
            },
            labels: {
            colors: ['white'],
            useSeriesColors: false
            },
          position: 'bottom'
          },
          title: {
          text: ''
          },
        },
        project_milestone_summary:
        {
          total_pending: 0,
          total_turn_on: 0,
        },
        seriesProjectMilestoneSummary: [],
        ChartOptionsProjectMilestoneSummary:
        {
            colors: ['#119ee1', '#0c765a', '#5fc7bc', '#bc5fc7', '#EADE1F', '#ef8215', '#F43314', '#BA4A00'],
            chart: {
            width: 380,
            height: 100,
            type: 'donut',
            },
            plotOptions: {
              pie: {
              startAngle: -90,
              endAngle: 270,
              },
            },
            labels: [],
            dataLabels: {
            enabled: true,
            },
            fill: {
            },
            legend: {
            formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
            },
            labels: {
            colors: ['white'],
            useSeriesColors: false
            },
          position: 'bottom'
          },
          title: {
          text: ''
          },
        },
        top_overdue_project: {
          top_1_site: null,
          top_2_site: null,
          top_3_site: null,
          top_4_site: null,
          top_5_site: null,
          top_1_mwp: 0,
          top_2_mwp: 0,
          top_3_mwp: 0,
          top_4_mwp: 0,
          top_5_mwp: 0,
        },
        top_overdue_document: {
          top_1_site: null,
          top_2_site: null,
          top_3_site: null,
          top_4_site: null,
          top_5_site: null,
          top_1_document: 0,
          top_2_document: 0,
          top_3_document: 0,
          top_4_document: 0,
          top_5_document: 0,
        },
      };
    },
    methods: {
   
      submissionDetailsModalShow(){
        this.modals.formSubmissionDetails = true
      },
      clearModal(){
        this.modals.formSubmissionDetails = false
      },
      getSubmissionDetails(bank_id, category, title)
      {
        // console.log(this.model.start_date, this.model.end_date, bank_id, category)
        this.modals.title = title
      
        axios.get(apiDomain + 'data/submission_data.by_category_bank_date_range/' + this.model.start_date + '/' + this.model.end_date + '/' + bank_id + '/' + category,{ headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.tableData = response.data
          this.fuseData()
          this.submissionDetailsModalShow()
          // const str = JSON.stringify(response.data, null, 2);
          // console.log(str)
        }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Bank Data Submission")
        });
      },
      fuseData()
      {
        this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3
        });
      },
      paginationChanged(page)
      {
          this.pagination.currentPage = page
      },
      updateData()
      {
        this.$notify({
        message:
          '<b>Data Sync : </b> - Data Sync with imortgage2u Portal in Progress.',
        timeout: 4500,
        icon: 'ni ni-bulb-61',
        type: 'default',
        });

        axios.get(apiDomain + 'data/submission_data.refresh',{ headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.$notify({
          message:
            '<b>Data Sync : </b> - Data Sync with imortgage Portal <b>COMPLETED</b>.',
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'info',
          });
          this.changeDateGetSubmissionDetails()
          this.model.start_date = '2022-01-01'
          this.model.end_date = currentDate
          this.chartData.start_date = '2022-01-01'
          this.chartData.end_date = currentDate
        }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Bank Data Submission")
        });
      },
      changeDateGetSubmissionDetails()
      {
        this.chartData.start_date = this.model.start_date
        this.chartData.end_date = this.model.end_date
        if(this.$refs.submission_chart != undefined)
        {
          this.$refs.submission_chart.changeData();
        }
        axios.get(apiDomain + 'data/submission_data.details.by_date_range/' + this.model.start_date + '/' + this.model.end_date ,{ headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.bank_submission_datas = response.data
          const obj = response.data

          let bank_name = [];
          let approved_count = [];
          let reject_count = [];

          Object.keys(obj).forEach(key => {
            bank_name.push(obj[key].bank_name)
            approved_count.push(obj[key].approved_count)
            reject_count.push(obj[key].reject_count)
          })

          this.chartData.bank_name = bank_name
          this.chartData.approved_count = approved_count
          this.chartData.reject_count = reject_count

          //           const str = JSON.stringify(this.chartData.bank_name, null, 2);
          // console.log(str);
          //     const str1 = JSON.stringify(this.chartData.approved_count, null, 2);
          // console.log(str1);
          //     const str2 = JSON.stringify(this.chartData.reject_count, null, 2);
          // console.log(str2);
   
        }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Bank Data Submission")
        });

        axios.get(apiDomain + 'data/submission_data.summary.by_date_range/' + this.model.start_date + '/' + this.model.end_date  ,{ headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.bank_submission_summary.total_count = response.data.total_count
          this.bank_submission_summary.total_actual_submission = response.data.total_actual_submission
          this.bank_submission_summary.total_approved_count = response.data.total_approved_count
          this.bank_submission_summary.total_reject_count = response.data.total_reject_count
          this.bank_submission_summary.total_decline_by_client_count = response.data.total_decline_by_client_count
          this.bank_submission_summary.total_cancel_count = response.data.total_cancel_count
          this.bank_submission_summary.total_other_count - response.data.total_other_count
          this.bank_submission_summary.total_customer = response.data.total_customer
          this.bank_submission_summary.total_spa_price = response.data.total_spa_price
          this.bank_submission_summary.total_loan_amount = response.data.total_loan_amount
          this.model.last_update_date = response.data.last_update_date
        }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Bank Data Submission")
        });
      },
      selectionChange(selectedRows)
      {
          this.selectedRows = selectedRows
      },
      changetitle()
      {
        this.model.title = this.model.radios.radio1
      },
      initBigChart(index) {
        let chartData = {
          datasets: [
            {
              label: 'Year 2021 Monthly Target',
              data: this.bigLineChart.allData[index]
            }
          ],
          labels: ['March', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        };
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      }
    },
    mounted() {
      this.initBigChart(0);
    }
  };
</script>
<style></style>
