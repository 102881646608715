<template>
  <div class="customer-profile-listing">
      <base-header class="pb-6">
          <b-row align-v="center" class="py-3">
              <b-col cols="6">
                  <h6 class="h1 d-inline-block mb-0 font-weight-bolder">Client Cashback Master List</h6>
              </b-col>
          </b-row>
      </base-header>
      <b-container fluid class="mt--6">
          <client-cashback-master-list-table></client-cashback-master-list-table>
          <!-- <master-list-formula-example></master-list-formula-example> -->
      </b-container>
  </div>
</template>
<script>
  import { Tabs, TabPane } from 'element-ui'
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import ClientCashbackMasterListTable from '@/views/Pages/Dashboard/ClientCashbackMasterList/ClientCashbackMasterListTable'
  import MasterListFormulaExample from '@/views/Pages/Dashboard/PMPMasterList/MasterListFormulaExample'

  export default {
      components: {
        ClientCashbackMasterListTable,
        MasterListFormulaExample,
        RouteBreadCrumb,
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
      },
      data() {
          return {
              backButton: false,
          };
      },
      created() {
          if(this.$route.params.token)
          {
              this.backButton = true;
          }
      },
      methods: {
          close() {
              if(this.$route.params.token)
              {
                  // window.location.href = "https://sas.imortgage2u.com/portal/initialSettlement/proposal/"+this.$route.params.proposal_id;
                  window.history.back();
                  window.close();
              }
              else
              {
                  this.$router.back();
              }
          }
      }
  }
</script>