<template>
    <div>
      <base-header class="pb-6 noprint">
        <b-row align-v="center" class="py-4">
            <b-col lg="6" cols="7">
                <h6 class="h2 text-white d-inline-block mb-0">Biz Performance</h6>
            </b-col>
        </b-row>
      </base-header>
      <b-container fluid class="mt--6">
        <b-card no-body>
            <b-card-header class="border-0">
                <b-col class="parent-iframe">
                    <iframe height="100%" width="100%" ref="myIframe" title="Report Section" class="responsive-iframe" src="https://app.powerbi.com/view?r=eyJrIjoiY2VlODQwM2YtMjE5Zi00NzYxLTllNGQtNzIwY2JjNWQ4NDg5IiwidCI6IjQxOTgwZDg5LWY2ZDUtNDdmNy04MmI0LWM3ZjYwMzliZDBlYyIsImMiOjEwfQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>
                </b-col>
            </b-card-header>
        </b-card>
      </b-container>
    </div>
</template>
<script>
export default {
    mounted() {
        this.$nextTick(() => {
            this.resizeIframe(); // Call the method when the component is mounted and the iframe is fully loaded
        });    
    },
    methods: {
        print(){
            window.print()
        },
        resizeIframe() {
            const iframe = this.$refs.myIframe;
            iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 2000) + 'px';
        },
    },
}
</script>
<style>
.parent-iframe{
    width: 100vw !important;
    height: 100vh !important;
}
.responsive-iframe{
    border: none;
    height: 100% !important;
    width: 100% !important;
}
</style>