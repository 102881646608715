export default {
  methods: {
    notifySuccess(message, timeout = 10000){
      this.$notify({
        message,
        timeout,
        icon: 'ni ni-bell-55',
        type: 'default',
      });
    },    
    notifyError(message, timeout = 10000){
      this.$notify({
        message,
        timeout,
        icon: 'ni ni-bulb-61',
        type: 'warning',
      });
    },
    redErrorNotify(error_message){
      this.notifyError(error_message)
    },
    errorFunction(error){    
      console.log(error)
      // check if validation error
      if(error.response.data.errors){
        let errors = error.response.data.errors
        let errorList = '<br>'
        let noError = 0
        Object.keys(errors).forEach(function(key)
        {
          Object.keys(errors[key]).forEach(function(value)
          {
            noError = noError + 1
            errorList = errorList + 'No. ' + noError + ' : ' +(errors[key][value])
            errorList = errorList + '<br>'
          });
        });

        this.notifyError('<b>Input Validation Warning :  </b>  ' + errorList)
        return;
      }

      //check if server error
      if(error.response.status === 500){
        let {message, file, line} = error.response.data
        var error = message

        if(file && line){
          error = message + '<br> File: '+file.split("/").pop()+ '<br>Line: '+line
        }
    
        this.notifyError(error)
        return;
      }

      // check if not found
      if(error.response.status === 404)
      {
        if (error.response.data.message === '' && error.response.data.exception) {
          this.notifyError(error.response.data.exception)
        }
        return;
      }

      // check if response error
      if (error.response.status == 401){
        if (this.$route.path !== '/login') {
          this.$router.push("/login");
        }
      }else{
        this.notifyError(error.response.data.message)
      }
    },
    underDevelopment(){
      this.notifyError('<b>This Function / Module is currently Under Development, Please come back later.</b>')
    },
  },

}
