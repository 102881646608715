<template>
  <b-card no-body class="initial-proposal-table">
      <b-card-header class="bg-transparent border-0">
      </b-card-header>
      <b-col cols="12">
        <b-row class="d-flex align-items-center">
          <b-col cols="12" md="2">
            <base-input label="Filter by Project:" >
              <el-select placeholder="Projects List" v-model="project.selected" @change="getPMPMasterList" filterable>
                <el-option value="All Project">All Project</el-option>
                <el-option v-for="option in project_list" :key="option.Project_Name" :label="option.Project_Name" v-bind:value="option.Project_Name">
                </el-option>
              </el-select>
            </base-input>
          </b-col>
          <b-col cols="12" md="2">
            <base-input label="Unit Status">
              <el-select
                  v-model="unit_status"
                  placeholder="Select Unit Status"
                  @change="getPMPMasterList"
                  >
                  <el-option
                      v-for="item in selectUnitOptions"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key"
                  >
                  </el-option>
              </el-select>
            </base-input>
          </b-col>
          <b-col cols="12" md="2" class="pt-2 mr-8">
            <el-date-picker
              v-model="dateValue"
              type="daterange"
              align="right"
              unlink-panels
              class="pt-2"
              start-placeholder="Start date"
              end-placeholder="End date"
              value-format="yyyy-MM-dd"
              @input="getPMPMasterList">
            </el-date-picker>
          </b-col>
          <b-col cols="12" md="2">
              <base-input
              label="Search"
              v-model="searchQuery"
              v-on:input="debouncedHandler"
              prepend-icon="fas fa-search"
              placeholder="Search...(case sensitive)"
              >
              </base-input>
          </b-col>
          <b-col md="2" class="text-right">
              <base-button size="sm" type="primary" :disabled="isDisabled" @click.prevent="exportForm('pdf')">Export To PDF</base-button>
              <base-button size="sm" type="primary" :disabled="isDisabled" @click.prevent="exportForm('excel')">Export To Excel</base-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col>
        <h5 class="text-danger">Note : <br>
          <i>Only be able to redirect to view customer details in IM2U, when you logged in as IM2U Account.</i>
        </h5>
        <br>
      </b-col>

      <el-table
          ref="multipleTable"
          :data="queriedData"
          v-loading="isTableLoading"
          style="width: 100%"
          border
          highlight-current-row
          max-height="800"
          sum-text="Total"
          empty-text="No Data"
          @selection-change="selectionChange"
      >
          <el-table-column
              prop="case_id"
              label="Case ID"
              min-width="80"
              width="auto"
              class-name="blue-header"
              sortable
          >
            <template slot-scope="scope">
                <!-- <b><router-link target="_blank" :to="'/case/case_view/' + scope.row.case_id + '/' + scope.row.project_id + '/' + scope.row.unit_id + '/' + scope.row.unitno_id + '/' + scope.row.customer_id">
                {{ scope.row.case_id }}
                </router-link></b> -->
                <div v-if="scope.row.case_id">
                  <a target="_blank" :href="`https://pmp.fintos.com.my/case/case_view/` + scope.row.case_id + '/' + scope.row.project_id + '/' + scope.row.unit_id + '/' + scope.row.unitno_id + '/' + scope.row.customer_id">{{ scope.row.case_id }}</a>
                </div>
            </template>
          </el-table-column>

          <el-table-column
              prop="unit_status"
              label="Status"
              min-width="120"
              width="auto"
              fixed
              label-class-name="text-left"
              class-name="text-center blue-header"
              sortable
          >
          </el-table-column>

          <el-table-column
              prop="submission_date"
              label="Submission Date"
              min-width="110"
              width="auto"
              class-name="blue-header"
              sortable
          >
          </el-table-column>

          <el-table-column
              prop="lo_signed_date"
              label="LO Signed Date"
              min-width="110"
              width="auto"
              class-name="blue-header"
              sortable
          >
          </el-table-column>

          <el-table-column
              prop="la_signed_date"
              label="LA Signed Date"
              min-width="110"
              width="auto"
              class-name="blue-header"
              sortable
          >
          </el-table-column>

          <el-table-column
              prop="spa_signed_date"
              label="SPA Signed Date"
              min-width="110"
              width="auto"
              class-name="blue-header"
              sortable
          >
          </el-table-column>

          <el-table-column
              prop="customer_name"
              label="Customer & ID"
              min-width="150"
              width="auto"
              class-name="blue-header"
              sortable
          >
            <template slot-scope="scope">
                <span class="truncate-text">{{ scope.row.customer_name }}</span>
                <div v-if="scope.row.customer_id">
                  / <a target="_blank" :href="`https://sas.imortgage2u.com/portal/preLoan/step1/`+scope.row.customer_id">{{ scope.row.customer_id }}</a>
                </div>
            </template>
          </el-table-column>

          <el-table-column
              prop="agent_name"
              label="IM2U Agent [SAS]"
              min-width="120"
              width="auto"
              class-name="blue-header"
              sortable
          >
            <template slot-scope="scope">
                <span class="truncate-text">{{ scope.row.agent_name }}</span>
            </template>
          </el-table-column>

          <el-table-column
              prop="bank_name"
              label="Bank"
              min-width="100"
              width="auto"
              class-name="blue-header"
              sortable
          >
          </el-table-column>

          <el-table-column
              prop="project_name"
              label="Project Name"
              min-width="130"
              width="auto"
              fixed
              label-class-name="text-left"
              class-name="text-center blue-header"
              sortable
          >
          </el-table-column>

          <el-table-column
              prop="unit_no"
              label="Unit & SQFT"
              min-width="100"
              width="auto"
              fixed
              label-class-name="text-left"
              class-name="text-center blue-header"
              sortable
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.unit_no }} / {{ scope.row.sqft }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
              prop="unit_price"
              label="Unit Price (MarkUp)"
              min-width="120"
              width="auto"
              class-name="blue-header"
              fixed
              sortable
          >
            <template slot-scope="scope">
                {{ scope.row.unit_price.toLocaleString(undefined, {maximumFractionDigits: 2}) }}
            </template>
          </el-table-column>

          <el-table-column
              prop="actual_loan_margin"
              label="Actual Loan Margin %"
              min-width="90"
              width="auto"
              class-name="blue-header"
              sortable
          >
            <template slot-scope="scope">
              <div v-if="scope.row.actual_loan_margin && (scope.row.create_lawyer_restriction == 0 || scope.row.create_lawyer_restriction == null)">
                {{ scope.row.actual_loan_margin.toLocaleString(undefined, {maximumFractionDigits: 2}) }} %
              </div>
            </template>
          </el-table-column>

          <el-table-column
              prop="loan_amount"
              label="Actual Loan Amount"
              min-width="120"
              width="auto"
              class-name="blue-header"
              sortable
          >
            <template slot-scope="scope">
              <div v-if="scope.row.loan_amount && (scope.row.create_lawyer_restriction == 0 || scope.row.create_lawyer_restriction == null)">
                {{ scope.row.loan_amount.toLocaleString(undefined, {maximumFractionDigits: 2}) }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Client Information" class-name="client-header">
            <el-table-column
                prop="actual_markup_cashback_to_customer"
                label="Actual Mark Up Buyer Discount %"
                class-name="client-header"
                min-width="90"
                width="auto"
                sortable
            >
              <template slot-scope="scope">
                <div v-if="scope.row.actual_markup_cashback_to_customer">
                  {{ scope.row.actual_markup_cashback_to_customer.toLocaleString(undefined, {maximumFractionDigits: 2}) }} %
                </div>
              </template>
            </el-table-column>

            <el-table-column
                prop="client_nett"
                label="Client Nett"
                class-name="client-header"
                min-width="120"
                width="auto"
                sortable
            >
              <template slot-scope="scope">
                <div v-if="scope.row.client_nett">
                  {{ scope.row.client_nett.toLocaleString(undefined, {maximumFractionDigits: 2}) }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
                prop="client_gross_cashback"
                label="Client Gross Cashback"
                class-name="client-header"
                min-width="150"
                width="auto"
                sortable
            >
              <template slot-scope="scope">
                <div v-if="scope.row.client_gross_cashback">
                  {{ scope.row.client_gross_cashback.toLocaleString(undefined, {maximumFractionDigits: 2}) }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
                prop="additional_cash_rebate_to_client"
                label="Extra Rebate Amount"
                class-name="client-header"
                min-width="120"
                width="auto"
                sortable
            >
              <template slot-scope="scope">
                <div v-if="scope.row.additional_cash_rebate_to_client > 0">
                  {{ scope.row.additional_cash_rebate_to_client.toLocaleString(undefined, {maximumFractionDigits: 2}) }}
                </div>
                <div v-else>0.00</div>
              </template>
            </el-table-column>

            <el-table-column
                prop="client_total_cashback_amount"
                label="Total Client Cashback Amount"
                class-name="client-header"
                min-width="100"
                width="auto"
                sortable
            >
              <template slot-scope="scope">
                  {{ scope.row.client_total_cashback_amount.toLocaleString(undefined, {maximumFractionDigits: 2}) }}
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
              prop="ssa_name"
              label="SSA"
              min-width="120"
              width="auto"
              class-name="blue-header"
              sortable
          >
            <template slot-scope="scope">
              <span class="truncate-text">{{ scope.row.ssa_name }}</span>
            </template>
          </el-table-column>

          <el-table-column
              prop="sse_name"
              label="SSE"
              min-width="120"
              width="auto"
              class-name="blue-header"
              sortable
          >
            <template slot-scope="scope">
              <span class="truncate-text">{{ scope.row.sse_name }}</span>
            </template>
          </el-table-column>
      </el-table>
      <br>
      <table width="70%" style="font-weight: 700">
          <tfoot v-if="clientCashbackData.total">
              <tr>
                  <td width="30%" class="px-4">&nbsp;&nbsp;Total Actual Loan Amount : </td>
                  <td width="40%">RM {{ clientCashbackData.total.total_actual_loan_amount.toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td>
              </tr>
              <tr>
                  <td width="30%" class="px-4">&nbsp;&nbsp;Total Client Nett :</td>
                  <td width="40%"> RM {{ clientCashbackData.total.total_client_nett.toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td>
              </tr>
              <tr>
                  <td width="30%" class="px-4">&nbsp;&nbsp;Total Client Gross Cashback :</td>
                  <td width="40%"> RM {{ clientCashbackData.total.total_client_gross_cashback.toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td>
              </tr>
              <tr>
                  <td width="30%" class="px-4">&nbsp;&nbsp;Total Extra Rebate Amount :</td>
                  <td width="40%"> RM {{ clientCashbackData.total.total_extra_rebate_amount.toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td>
              </tr>
              <tr>
                  <td width="30%" class="px-4">&nbsp;&nbsp;Total Client Cashback :</td>
                  <td width="40%"> RM {{ clientCashbackData.total.total_client_cashback_amount.toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td>
              </tr>
          </tfoot>
      </table>
      <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-md-between flex-wrap"
      >
          <div class="d-flex align-items-center mb-3">
              <p class="card-category mb-0">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
      
                  <span v-if="selectedRows.length">
                      &nbsp; &nbsp; {{selectedRows.length}} rows selected
                  </span>
              </p>

              <b class="mb-0 ml-4">Rows:</b>

              <el-select
                  class="select-primary pagination-select mx-2"
                  v-model="pagination.perPage"
                  placeholder="Per page"
              >
                  <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
              </el-select>
  
          </div>
          <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
          >
          </base-pagination>
      </div>
  </b-card>
</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option, Button, Badge, Tag, DatePicker } from 'element-ui'
  import { BasePagination } from '@/components'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios';
  import "flatpickr/dist/flatpickr.css";
  import debounce from 'lodash.debounce';

  export default {
      name: 'client-cashback-master-list-table',
      mixins: [ErrorFunctionMixin,clientPaginationMixin],
      components: {
          BasePagination,
          [Select.name]: Select,
          [Option.name]: Option,
          [Tooltip.name]: Tooltip,
          [Table.name]: Table,
          [TableColumn.name]: TableColumn,
          [Dropdown.name]: Dropdown,
          [DropdownItem.name]: DropdownItem,
          [DropdownMenu.name]: DropdownMenu,
          [Button.name]: Button,
          [Badge.name]: Badge,
          [Tag.name]: Tag,
          [DatePicker.name]: DatePicker,
      },
      data() {
        return {
          isTableLoading: true,
          propsToSearch:[
            'unit_status', 'contract_date', 'expired_date', 'project_name', 'unit_no', 'unit_type_name',
            'pmp_master_listing_price', 'pmp_master_spa_price', 'unit_price', 'customer_name',
            'customer_id', 'case_id', 'agent_name', 'ssa_name', 'sse_name', 'bank_name'
          ],
          isFixed: true,
          currentPage: 1,
          mode: 'listing',
          selectedRows: [],
          dates: {
              start_date: null,
              end_date: null,
              current_date: null,
          },
          unit_status: '',
          searchText: '',
          searchQuery: '',
          TableColumn:[{
              type: 'selection'
          }],
          selectUnitOptions: [
            {
              key:'',
              value: 'All',
            },
            {
              key:'Open',
              value: 'Open',
            },
            {
              key:'Reserved',
              value: 'Reserved',
            },
            {
              key: 'Booked',
              value: 'Booked',
            },
            {
              key: 'Locked',
              value: 'Locked',
            },
            {
              key: 'Not Available',
              value: 'Not Available',
            },
            {
              key: 'Sold',
              value: 'Sold',
            },
          ],
          project: {
            selected: "All Project",
          },
          project_list: [],
          status: [],
          tableData: [],
          isDisabled: false,
          dateValue: [],
          clientCashbackData: [],
        };
      },
      created(){
        const dateObj = new Date();
        const year = dateObj.getFullYear();
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const date = ("0" + dateObj.getDate()).slice(-2);
        const hour = dateObj.getHours()+":";
        const minutes = dateObj.getMinutes()+":";
        const seconds = dateObj.getSeconds();
        this.dates.current_date = `${year}-${month}-${date} ${hour}${minutes}${seconds}`;

        // this.getPMPMasterList();
        this.projectListing()
        this.pagination.perPage = 1000;
      },
      mounted() {
        this.debouncedHandler = debounce(() => {
            this.getPMPMasterList();
        }, 1000);
      },
      methods: {
        selectionChange(selectedRows) {
          this.selectedRows = selectedRows
        },
        paginationChanged(page)
        {
          this.pagination.currentPage = page
        },
        projectListing(){
          this.isTableLoading = true;
          axios.get(apiDomain + 'data/project', { headers: getHeader() })
          .then(response => {
            this.project_list = response.data.data
            this.isTableLoading = false;
          })
          .catch ( error => {
              this.errorFunction(error, "case") 
              this.isTableLoading = false;
          });
          const str = JSON.stringify(this.tableData, null, 2);
        },
        getPMPMasterList() {
          let query_params = "";
          
          if(this.project.selected == 'All Project')
          {
            query_params = "?project_name=&unit_status=" + this.unit_status;
          }
          else
          {
            query_params = "?project_name=" + this.project.selected + "&unit_status=" + this.unit_status;
          }

          if(this.dateValue !== undefined && this.dateValue !== null)
          {
            if(this.dateValue[0] !== undefined)
            {
              query_params += "&date_from=" + this.dateValue[0] + "&date_to=" + this.dateValue[1];
            }
          }

          query_params += "&client_report=true";

          this.isTableLoading = true;

          // this.$notify({
          //   message: 'Please wait.<br>Data Loading In Progress.',
          //   icon: 'ni ni-bell-55',
          //   timeout: 2000,
          //   type: 'default',
          // });
          
          axios.get(apiDomain + 'data/get_client_cashback_master_list' + query_params,{ headers: getHeader() })
          .then ( response => {
              if (response.status === 200)
              {
                this.tableData = response.data.data;
                this.clientCashbackData = response.data;
                // this.$notify({
                //   message: 'Data successfully loaded.',
                //   icon: 'ni ni-bell-55',
                //   timeout: 1500,
                //   type: 'success',
                // });
                this.isTableLoading = false;
              }
          })
          .catch ( error => {
              this.errorFunction(error, "Get Data from API")
          });
        },
        debouncedHandler() {},
        exportForm(export_type)
        {
          let project_name = '';
          let file_type = '';
          let date_from = null;
          let date_to = null;

          if(this.project.selected != 'All Project')
          {
            project_name = this.project.selected;
          }

          if(this.dateValue !== undefined && this.dateValue !== null)
          {
            if(this.dateValue[0] !== undefined)
            {
              date_from = this.dateValue[0];
              date_to = this.dateValue[1];
            }
          }

          if(export_type == 'pdf')
          {
            file_type = '.pdf';
          }
          else
          {
            file_type = '.xlsx';
          }

          const postData = {
              project_name: project_name,
              unit_status: this.unit_status,
              report_type: 'client_cashback',
              date_from: date_from,
              date_to: date_to,
              export_type: export_type,
          }
          // this.$notify({
          //     message: '<b>Generating Excel Report.<br>This may takes a few seconds.<br>When finished, the excel report will be downloaded automatically.',
          //     icon: 'ni ni-bell-55',
          //     timeout: 5000,
          //     type: 'default',
          // });

          this.isDisabled = true;

          axios.post(apiDomain + 'data/client_cashback_summary.export', postData, {responseType: 'arraybuffer', headers: getHeader()})
          .then(response => {
              let fileURL = window.URL.createObjectURL(new Blob([response.data]));
              let fileLink = document.createElement('a');
              fileLink.href = fileURL;
              let report_name = "Client Cashback Summary";
              if(date_from != null)
              {
                report_name = report_name+"_"+date_from+'_'+date_to;
              }
              fileLink.setAttribute('download', report_name+file_type);
              document.body.appendChild(fileLink);
              fileLink.click();
              // this.$notify({
              //     message:
              //         '<b>Excel Report for <b>'+report_name+"_"+this.dates.current_date+'</b> Successfully Generated.',
              //     icon: 'ni ni-bell-55',
              //     timeout: 5000,
              //     type: 'success',
              // });
              this.isDisabled = false;
          })
        },
        generatePDFReport(row)
        {
          const url = '/case/case_view/client_cashback_summary/' + row.case_id + '/' + row.project_id
            + '/' + row.unit_id
            + '/' + row.unitno_id + '/' + row.customer_id;

          window.open(url, '_blank');
        },
        sendPDFToCustomer(row)
        {
          const postData = {
            case_id: row.case_id,
            customer_id: row.customer_id,
          }
          axios.post(apiDomain + 'data/client_cashback_summary.send_pdf_to_customer',postData, { headers: getHeader() })
          .then(response => {
            if(response.status === 200)
            {
              this.$notify({
                  message:
                  '<b>Client Cashback Summary PDF Report - Successfully Send to Customer.',
                  timeout: 3000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
              });
            }
          })
          .catch ( error => {
              this.errorFunction(error, "Send PDF Error") 
          });
        }

      }
  }
</script>
<style scoped>
.report-action {
  width: 80px
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle){
  margin-top: 0.5rem;
  border-radius: 0.25rem;
}
.btn-group-vertical > .btn:not(:first-child){
  margin-top: 0.5rem;
  border-radius: 0.25rem;
}
</style>