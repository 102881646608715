<template>
    <div class="customer-profile-listing">
        <base-header class="pb-6">
            <b-row align-v="center" class="py-3">
                <b-col cols="6">
                    <h6 class="h1 d-inline-block mb-0 font-weight-bolder">Client Acceptance</h6>
                </b-col>
            </b-row>
        </base-header>
        <b-container fluid class="mt--6">
            <client-acceptance-report-table></client-acceptance-report-table>
        </b-container>
    </div>
</template>
<script>
    import { Tabs, TabPane } from 'element-ui'
    import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
    import ClientAcceptanceReportTable from '@/views/Pages/Dashboard/InitialProposal/ClientAcceptanceReport/ClientAcceptanceReportTable'

    export default {
        components: {
            ClientAcceptanceReportTable,
            RouteBreadCrumb,
            [Tabs.name]: Tabs,
            [TabPane.name]: TabPane,
        },
        data() {
            return {
                modals:{
                    show_new_customer: false
                },
                backButton: false,
            };
        },
        created() {
            if(this.$route.params.token)
            {
                this.backButton = true;
            }
        },
        methods: {
            close() {
                if(this.$route.params.token)
                {
                    // window.location.href = "https://sas.imortgage2u.com/portal/initialSettlement/proposal/"+this.$route.params.proposal_id;
                    window.history.back();
                    window.close();
                }
                else
                {
                    this.$router.back();
                }
            }
        }
    }
</script>