<template>
  <card>
    <b-row align-v="center" slot="header" >
      <b-col cols="8">
        <h3 class="mb-0">Edit profile </h3>
      </b-col>
    </b-row>

    <b-form @submit.prevent="updateProfile">
      <h6 class="heading-small text-muted mb-4">User information</h6>

      <div class="pl-lg-4">
        <b-row>
          <b-col lg="6">
            <base-input
              type="text"
              label="Email/Username"
              placeholder="Email/Username"
              v-model="user.email"
              readonly
            >
            </base-input>
          </b-col>
          <b-col lg="6">
            <base-input
              type="text"
              label="Name"
              placeholder="Name"
              v-model="user.name"
            >
            </base-input>
          </b-col>
        </b-row>
      </div>

      <div class="pl-lg-4">
        <b-row>
          <b-col lg="6">
            <base-input label="Password"
                name="password"
                type="password"
                placeholder="Password"
                v-model="user.password">
          </base-input>
          </b-col>
          <b-col lg="6">
            <base-input label="Password Confirmation"
                name="password_confirmation"
                type="password"
                placeholder="Password Confirmation"
                v-model="user.password_confirmation">
          </base-input>
          </b-col>
        </b-row>
      </div>

      <h5 class="text-danger"> Note : <br><br>
                <i>
                1. Password must be at least 10 characters in length.
                </i>
                <br>
                <i>
                2. Password must contain at least one lowercase letter. [a-z].
                </i>
                <br>
                <i>
                3. Password must contain at least one uppercase letter. [A-Z].
                </i>
                <br>
                <i>
                4. Password must contain at least one number. [0-9].
                </i>
                <br>
                <i>
                5. Password  must contain a special character from the list. [@$!%*#?&].
                </i>
                <br>
                <i>
                6. Password cannot have empty space.
                </i>
        </h5>

      <hr class="my-4">

      <base-button type="primary" @click="createData()">Update / Save </base-button>

    </b-form>
  </card>
</template>
<script>

import BaseSlider from '@/components/BaseSlider'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate, getUserInfo} from '@/assets/js/config.js'
import axios from 'axios'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate();
      var userInfo = getUserInfo();
      this.getData();

      axios.get(apiDomain + 'lookup/role_list', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.roles = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Role")
      } );
    },
  data() {
    return {
      user: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      }
    };
  },
  methods: {
    getData()
    {
      this.user.name = getUserInfo().name;
      this.user.email = getUserInfo().email;
      this.user.id = getUserInfo().id;
    },

    updateProfile() {
      alert('Your data: ' + JSON.stringify(this.user));
    },

    createData(){
        if ( !this.user.name )
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Create New Developer </b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          //console.log(this.user)
          axios.put(apiDomain + 'api/my_profile/' + this.user.id, this.user, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>Edit User: ' + this.user.name + '</b> - Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.user.password = '';
                this.user.password_confirmation = '';
                //this.$router.back()
            }
          })
          .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Update User Information")
          });
        }
      },
  }
};
</script>
<style></style>
