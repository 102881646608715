<template>
    <div>
      <base-header class="pb-6 noprint">
        <b-row align-v="center" class="py-4">
            <b-col lg="6" cols="7">
                <h6 class="h2 text-white d-inline-block mb-0">Commission Report</h6>
            </b-col>
            <!-- <b-col lg="6" cols="5" class="text-right">
                <base-button size="sm" type="primary" @click.prevent="print">Print</base-button>
            </b-col> -->
        </b-row>
      </base-header>
      <b-container fluid class="mt--6">
        <b-card no-body>
            <b-card-header class="border-0">
                <b-col class="parent-iframe">
                    <iframe height="100%" width="100%" ref="myIframe" title="Report Section" class="responsive-iframe" src="https://app.powerbi.com/view?r=eyJrIjoiZTVmNjBjZTgtOTFhMS00MjE1LTliZGQtNzYzZTFmZDlhNDY4IiwidCI6IjQxOTgwZDg5LWY2ZDUtNDdmNy04MmI0LWM3ZjYwMzliZDBlYyIsImMiOjEwfQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>
                </b-col>
            </b-card-header>
        </b-card>
      </b-container>
    </div>
</template>
<script>
export default {
    
}
</script>
