<template>
    <b-card no-body class="initial-proposal-table">
        <b-card-header class="bg-transparent border-0">
        </b-card-header>
        <b-col cols="12">
            <b-row class="d-flex align-items-center">
                <b-col cols="12" md="2">
                    <base-input
                    label="Search"
                    v-model="searchText"
                    v-on:input="debouncedHandler"
                    prepend-icon="fas fa-search"
                    placeholder="Search..."
                    >
                    </base-input>
                </b-col>
                <b-col cols="12" md="3">
                    <base-input :label="startDateLabel" >
                        <flat-picker placeholder="Y-m-d" class="form-control datepicker" v-model="dates.start_date" @on-change="getInitialSettlementDetails"></flat-picker>
                    </base-input>
                </b-col>
                <b-col cols="12" md="3">
                    <base-input :label="endDateLabel" >
                        <flat-picker class="form-control datepicker" v-model="dates.end_date" @on-change="getInitialSettlementDetails"></flat-picker>
                    </base-input>
                </b-col>
                <b-col cols="12" md="4" class="text-right" style="padding: 2rem;">
                    <base-button size="sm" type="primary" :disabled="isDisabled" @click="exportForm">Export To Excel</base-button>
                </b-col>
            </b-row>
            <!-- <b-row class="d-flex align-items-center">
                <b-col cols="12" md="2">
                    <base-input :label="startDateLabel" >
                        <flat-picker placeholder="Y-m-d" class="form-control datepicker" v-model="dates.start_date" @on-change="getInitialSettlementDetails"></flat-picker>
                    </base-input>
                </b-col>
                <b-col cols="12" md="2">
                    <base-input :label="endDateLabel" >
                        <flat-picker class="form-control datepicker" v-model="dates.end_date" @on-change="getInitialSettlementDetails"></flat-picker>
                    </base-input>
                </b-col>
                <b-col cols="12" md="auto" class="text-right" style="padding: 2rem;">
                    <base-button size="sm" type="primary" @click="getInitialSettlementDetails(true)">Search</base-button>
                </b-col>
            </b-row> -->
        </b-col>

        <el-table
            header-row-class-name="thead-dark"
            ref="multipleTable"
            :data="queriedData"
            style="width: 100%"
            border
            max-height="800"
            show-summary
            :summary-method="getSummaries"
            sum-text="Total"
            empty-text="No Data"
            @selection-change="selectionChange"
        >
            <!-- <el-table-column
                type="selection"
                width="60"
            ></el-table-column> -->

            <el-table-column
                prop="id"
                label="Proposal ID"
                min-width="110"
                width="auto"
                label-class-name="text-left"
                class-name="text-center"
                sortable
            >
            </el-table-column>

            <el-table-column
                prop="customer_name"
                label="Customer Name / Customer ID"
                fixed
                min-width="180"
                width="auto"
                sortable
            >
                <template slot="header">
                    <div>
                        Customer Name <br>/ Customer ID
                    </div>
                </template>
                <template slot-scope="scope">
                    <span class="truncate-text">{{ scope.row.customer_name }}</span>
                    <div class="column-name d-flex align-items-center">
                         / {{ scope.row.customer_id }}
                        <b-iconstack font-scale="2" class="float-right mx-2 mr-3">
                            <b-icon stacked icon="square-fill" :style="{'color':scope.row.basket_color}"></b-icon>
                            <b-icon stacked icon="basket2" scale="0.5" class="text-white"></b-icon>
                        </b-iconstack>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                prop="agent_fullname"
                label="Agent Fullname / Agent ID"
                min-width="130"
                width="auto"
                sortable
            >
                <template slot-scope="scope">
                    <template v-if="scope.row.agent_fullname">
                        <span class="truncate-text">{{ scope.row.agent_fullname }}</span> 
                        <div>/ {{ scope.row.agent_id }}</div>
                    </template>
                </template>
            </el-table-column>

            <el-table-column
                prop="sse_fullname"
                label="SSE Fullname / SSE ID"
                min-width="130"
                width="auto"
                sortable
            >
                <template slot-scope="scope">
                    <template v-if="scope.row.sse_fullname">
                        <span class="truncate-text">{{ scope.row.sse_fullname }}</span> 
                        <div>/ {{ scope.row.sse_agent_id }}</div>
                    </template>
                </template>
            </el-table-column>

            <el-table-column
                prop="ssa_fullname"
                label="SSA Fullname / SSA Advisor ID"
                min-width="130"
                width="auto"
                sortable
            >
                <template slot-scope="scope">
                    <template v-if="scope.row.ssa_fullname">
                        <span class="truncate-text">{{ scope.row.ssa_fullname }}</span>
                        <div>/ {{ scope.row.ssa_agent_id }}</div>
                    </template>
                </template>
            </el-table-column>

            <el-table-column
                prop="prod_prop_name_1"
                label="Project 1 <br>& Total (RM)"
                min-width="200"
                width="auto"
                sortable
            >
                <template slot="header">
                    <div>
                        Project 1 <br>& Total (RM)
                    </div>
                </template>
                <template slot-scope="scope">
                    <div v-if="scope.row.prod_prop_name_1">
                        {{ scope.row.prod_prop_name_1 }} <br>
                        <div v-if="scope.row.prod_prop_1">(RM {{ scope.row.prod_prop_1.toLocaleString(undefined, {minimumFractionDigits: 2}) }})</div>
                    </div>
                </template>
            </el-table-column>

            <!-- <el-table-column
                prop="prod_prop_name_2"
                label="Project 2 & Total (RM)"
                min-width="550"
                width="auto"
                sortable
            >
                <template slot-scope="scope">
                    <div v-if="scope.row.prod_prop_name_2">
                        {{ scope.row.prod_prop_name_2 }} 
                        <span v-if="scope.row.prod_prop_2">(RM {{ scope.row.prod_prop_2.toLocaleString(undefined, {minimumFractionDigits: 2}) }})</span>
                    </div>
                </template>
            </el-table-column>    -->

            <el-table-column
                prop="customer_gross_income"
                label="Gross Income (RM)"
                min-width="120"
                width="auto"
                sortable
            >
                <template slot-scope="scope">
                    <div v-if="scope.row.customer_gross_income">
                        {{ scope.row.customer_gross_income | moneyFormat }}
                    </div>
                    <div v-else>0.00</div>
                </template>
            </el-table-column>

            <el-table-column
                prop="strategic_total"
                label="Total Settlement (RM)"
                min-width="180"
                width="auto"
                sortable
            >
                <template slot="header">
                    <div>
                        Total <br>Settlement (RM)
                    </div>
                </template>
                <template slot-scope="scope">
                    <div v-if="scope.row.strategic_total">
                        {{ scope.row.strategic_total.toLocaleString(undefined, {minimumFractionDigits: 2}) }}
                    </div>
                    <div v-else>0.00</div>
                </template>
            </el-table-column>

            <el-table-column
                prop="strategic_merchant"
                label="Merchant Settlement (RM)"
                min-width="180"
                width="auto"
                sortable
            >
                <template slot="header">
                    <div>
                        Merchant <br>Settlement (RM)
                    </div>
                </template>
                <template slot-scope="scope">
                    <div v-if="scope.row.strategic_merchant">
                        {{ scope.row.strategic_merchant.toLocaleString(undefined, {minimumFractionDigits: 2}) }}
                    </div>
                    <div v-else>0.00</div>
                </template>
            </el-table-column>

            <el-table-column
                prop="strategic_credit_loan"
                label="Invinity Settlement (RM)"
                min-width="180"
                width="auto"
                sortable
            >
                <template slot="header">
                    <div>
                        Invinity <br>Settlement (RM)
                    </div>
                </template>
                <template slot-scope="scope">
                    <div v-if="scope.row.strategic_credit_loan">
                        {{ scope.row.strategic_credit_loan.toLocaleString(undefined, {minimumFractionDigits: 2}) }}
                    </div>
                    <div v-else>0.00</div>
                </template>
            </el-table-column> 

            <el-table-column
                prop="customer_initial_proposal_date"
                label="Customer Agreement Initial Proposal"
                min-width="150"
                width="auto"
                sortable
            >
            </el-table-column> 
            
            <template>
                <el-table-column
                    prop="client_acceptance_aging_days"
                    label="Aging Days"
                    min-width="80"
                    width="auto"
                    sortable
                >
                    <template slot-scope="scope">
                        <span class="text-red">{{ scope.row.client_acceptance_aging_days }} Days</span>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="client_accepted_date"
                    label="Client Acceptance Date"
                    min-width="150"
                    width="auto"
                    sortable
                >
                </el-table-column> 

                <el-table-column
                    prop="case_date"
                    label="Pre-Submission Date"
                    min-width="150"
                    width="auto"
                    sortable
                >
                </el-table-column>

                <el-table-column
                    prop="submission_date"
                    label="Submission Date"
                    min-width="150"
                    width="auto"
                    sortable
                >
                </el-table-column>

                <el-table-column
                    prop="lo_signed_date"
                    label="LO Signed Date"
                    min-width="150"
                    width="auto"
                    sortable
                >
                </el-table-column>

                <el-table-column
                    prop="spa_signed_date"
                    label="SPA Signed Date"
                    min-width="150"
                    width="auto"
                    sortable
                >
                </el-table-column>

                <el-table-column
                    prop="la_signed_date"
                    label="LA Signed Date"
                    min-width="150"
                    width="auto"
                    sortable
                >
                </el-table-column>
            </template>           
        </el-table>
        <b-col sm="6" md="12">
            <p class="card-category px-4 mb-0">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
        </b-col>
        <br>
        <b-col sm="6" md="12">
            <table width="70%">
                <thead v-if="initialSettlementData.total">
                    <tr>
                        <td width="30%" class="px-4">Total Settlement : </td>
                        <td width="40%"><b> RM {{ initialSettlementData.total.total_strategic_total | moneyFormat }}</b></td>
                    </tr>
                    <tr>
                        <td width="30%" class="px-4">Total Merchant Settlement :</td>
                        <td width="40%"><b> RM {{ initialSettlementData.total.total_strategic_merchant | moneyFormat }}</b></td>
                    </tr>
                    <tr>
                        <td width="30%" class="px-4">Total Invinity Settlement :</td>
                        <td width="40%"><b> RM {{ initialSettlementData.total.total_strategic_credit_loan | moneyFormat }}</b></td>
                    </tr>
                    <tr>
                        <td width="30%" class="px-4">Total Project :</td>
                        <td width="40%"><b> RM {{ initialSettlementData.total.total_prod_prop_1 | moneyFormat }}</b></td>
                    </tr>
                </thead>
            </table>
            <br>
            <b-row>
                <b-col md="4">
                    <div class="client-accept-total" v-show="clientAccept">
                        <!-- project total -->
                        <table width="100%">
                            <thead>
                                <th class="px-4">Project Detail</th>
                                <th class="px-4">Total Client</th>
                                <th class="px-4">Project Total (RM)</th>
                            </thead>
                            <tbody v-if="projectData">
                                <tr v-for="project in projectData" :key="project.id">
                                    <td width="30%" class="px-4">{{ project.prod_prop_name_1 }}</td>
                                    <td width="10%" class="px-4 text-center">{{ project.total_client }}</td>
                                    <td width="20%" class="px-4" v-if="project.project_total"><b>RM {{ project.project_total | moneyFormat }}</b></td>
                                    <td width="20%" class="px-4" v-else>-</td>
                                </tr>
                            </tbody>
                        </table>
                        <br>
                        <!-- ssa total -->
                        <table width="100%">
                            <thead>
                                <th class="px-4">SSA</th>
                                <th class="px-4">Total Client</th>
                                <th class="px-4">Project Total (RM)</th>
                            </thead>
                            <tbody v-if="ssaData">
                                <tr v-for="ssa in ssaData" :key="ssa.id">
                                    <td width="30%" class="px-4">{{ ssa.ssa_fullname }}</td>
                                    <td width="10%" class="px-4 text-center">{{ ssa.total_client }}</td>
                                    <td width="20%" class="px-4" v-if="ssa.project_total"><b>RM {{ ssa.project_total | moneyFormat }}</b></td>
                                    <td width="20%" class="px-4" v-else>-</td>
                                </tr>
                            </tbody>
                        </table>
                        <br>
                        <!-- sse total -->
                        <table width="100%">
                            <thead>
                                <th class="px-4">SSE</th>
                                <th class="px-4">Total Client</th>
                                <th class="px-4">Project Total (RM)</th>
                            </thead>
                            <tbody v-if="sseData">
                                <tr v-for="sse in sseData" :key="sse.id">
                                    <td width="30%" class="px-4">{{ sse.sse_fullname }}</td>
                                    <td width="10%" class="px-4 text-center">{{ sse.total_client }}</td>
                                    <td width="20%" class="px-4" v-if="sse.project_total"><b>RM {{ sse.project_total | moneyFormat }}</b></td>
                                    <td width="20%" class="px-4" v-else>-</td>
                                </tr>
                            </tbody>
                        </table>
                        <br>
                        <!-- agent total -->
                        <table width="100%">
                            <thead>
                                <th class="px-4">Agent</th>
                                <th class="px-4">Total Client</th>
                                <th class="px-4">Project Total (RM)</th>
                            </thead>
                            <tbody v-if="agentData">
                                <tr v-for="agent in agentData" :key="agent.id">
                                    <td width="30%" class="px-4">{{ agent.agent_fullname }}</td>
                                    <td width="10%" class="px-4 text-center">{{ agent.total_client }}</td>
                                    <td width="20%" class="px-4" v-if="agent.project_total"><b>RM {{ agent.project_total | moneyFormat }}</b></td>
                                    <td width="20%" class="px-4" v-else>-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </b-col>
                <b-col md="8">
                    <client-acceptance-chart :project-data="projectData" :ssa-data="ssaData" :sse-data="sseData" :agent-data="agentData"></client-acceptance-chart>
                </b-col>
            </b-row>

        </b-col>
        <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-md-between flex-wrap"
        >
            <div class="d-flex align-items-center mb-3">
                <p class="card-category mb-0">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        
                    <span v-if="selectedRows.length">
                        &nbsp; &nbsp; {{selectedRows.length}} rows selected
                    </span>
                </p>

                <b class="mb-0 ml-4">Rows:</b>

                <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                >
                    <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                    </el-option>
                </el-select>
            </div>
            <base-pagination
                class="pagination-no-border"
                :current="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
                @change="paginationChanged($event)"
            >
            </base-pagination>
        </div>
    </b-card>
</template>
<script>
    import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip, Select, Option, Button, Badge, Tag } from 'element-ui'
    import { BasePagination } from '@/components'
    import clientPaginationMixin from '@/assets/js/clientPaginationMixin'
    import notifyFunctionMixin from '@/assets/js/notifyFunctionMixin'
    import {apiDomain, getHeader} from '@/assets/js/config.js'
    import axios from 'axios';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import debounce from 'lodash.debounce';
    import BarChart from '@/components/Charts/BarChart';
    import PieChart from '@/components/Charts/PieChart';
    import ClientAcceptanceChart from '@/views/Pages/Dashboard/InitialProposal/ClientAcceptanceReport/ClientAcceptanceChart'


    export default {
        name: 'initial-proposal-report-table',
        mixins: [notifyFunctionMixin,clientPaginationMixin],
        components: {
            BasePagination,
            flatPicker,
            [Select.name]: Select,
            [Option.name]: Option,
            [Tooltip.name]: Tooltip,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            [Dropdown.name]: Dropdown,
            [DropdownItem.name]: DropdownItem,
            [DropdownMenu.name]: DropdownMenu,
            [Button.name]: Button,
            [Badge.name]: Badge,
            [Tag.name]: Tag,
            BarChart,
            PieChart,
            ClientAcceptanceChart,
        },
        data() {
            return {
                propsToSearch:['id', 'customer_name', 'agent_fullname', 
                    'sse_fullname', 'ssa_fullname', 'aging_days', 'strategic_total',
                    'strategic_merchant', 'strategic_credit_loan', 'prod_prop_name_1',
                    'prod_prop_name_2'
                ],
                isFixed: true,
                currentPage: 1,
                selectedRows: [],
                dates: {
                    start_date: null,
                    end_date: null,
                    current_date: null,
                },
                report_type: 'client_accept',
                searchText: '',
                searchQuery: '',
                tableData: [],
                initialSettlementData: [],
                startDateLabel: null,
                endDateLabel: null,
                isDisabled: false,
                clientAccept: false,
                projectData: [],
                ssaData: [],
                sseData: [],
                agentData: [],
            };
        },
        created(){
            const dateObj = new Date();
            const year = dateObj.getFullYear();
            const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
            const date = ("0" + dateObj.getDate()).slice(-2);
            const hour = dateObj.getHours()+":";
            const minutes = dateObj.getMinutes()+":";
            const seconds = dateObj.getSeconds();

            this.dates.start_date = `${year}-${month}-1`;
            this.dates.end_date = `${year}-${month}-${date}`;
            this.dates.current_date = `${year}-${month}-${date} ${hour}${minutes}${seconds}`;
            this.getInitialSettlementDetails();
        },
        mounted() {
            this.debouncedHandler = debounce(() => {
                this.getInitialSettlementDetails();
            }, 1000);
        },
        methods: {
            selectionChange(selectedRows) {
                this.selectedRows = selectedRows
            },
            paginationChanged(page)
            {
                this.pagination.currentPage = page
            },
            getDateLabel() {
                switch(this.report_type)
                {
                    case 'processing':
                        this.startDateLabel = 'Initial Proposal Start Date';
                        this.endDateLabel = 'Initial Proposal End Date';
                    break;
                    
                    case 'credit_control':
                        this.startDateLabel = 'Credit Control Acceptance Start Date';
                        this.endDateLabel = 'Credit Control Acceptance End Date';
                    break;

                    case 'client_accept':
                        this.startDateLabel = 'Client Acceptance Start Date';
                        this.endDateLabel = 'Client Acceptance End Date';
                    break;

                    default:
                        this.startDateLabel = 'Credit Control Acceptance Start Date';
                        this.endDateLabel = 'Credit Control Acceptance End Date';
                }
            },
            getInitialSettlementDetails() {

                this.getDateLabel();

                const query_params = "?search=" + this.searchText + "&start_date=" + this.dates.start_date + "&end_date=" + this.dates.end_date;

                this.$notify({
                    message: 'Please wait.<br>Data Loading In Progress.',
                    timeout: 3000,
                    icon: 'ni ni-bell-55',
                    type: 'default',
                });

                axios.get(apiDomain + 'data/initial_settlement_data.by_client_accept' + query_params,{ headers: getHeader() })
                .then ( response => {
                    if (response.status === 200)
                    {
                        this.initialSettlementData = response.data;
                        this.clientAccept = true;
                        this.fetchClientAcceptanceSummaryData();
                        this.tableDate = [];
                        this.tableData = response.data.data
                        this.$notify({
                            message: 'Data successfully being loaded.',
                            timeout: 3000,
                            icon: 'ni ni-bell-55',
                            type: 'success',
                        });
                    }
                })
                .catch ( error => {
                    this.errorFunction(error, "Get Data from API")
                });
            },
            debouncedHandler() {},
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                let values = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Total';
                        return;
                    }
                    if(column.property == 'strategic_total' || column.property == 'strategic_merchant' || column.property == 'strategic_credit_loan')
                    {
                        values = data.map(item => Number(item[column.property]));
                    
                        if (!values.every(value => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0);
                        } else {
                            sums[index] = '';
                        }
                    }
                });
                const result = sums.map((item,index) => {
                    if(index !== 0 && item != "")
                    {
                        item = "RM " + parseFloat(item).toLocaleString(undefined, {minimumFractionDigits: 2});
                    }
                    return item;
                });
                return result;
            },
            exportForm()
            {
                const postData = {
                    report_type: 'client_accept',
                    start_date: this.dates.start_date,
                    end_date: this.dates.end_date,
                    search: this.searchText,
                }
                this.$notify({
                    message: '<b>Generating Excel Report.<br>This may takes a few seconds.<br>When finished, the excel report will be downloaded automatically.',
                    timeout: 5000,
                    icon: 'ni ni-bell-55',
                    type: 'default',
                });

                this.isDisabled = true;

                axios.post(apiDomain + 'data/initial_settlement_data.export', postData, {responseType: 'arraybuffer', headers: getHeader()})
                .then(response => {
                    let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    let fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let report_name = "Client Acceptance Report";
                                            
                    fileLink.setAttribute('download', report_name+"_"+this.dates.current_date+'.xlsx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.$notify({
                        message:
                            '<b>Excel Report for <b>'+report_name+"_"+this.dates.current_date+'</b> Successfully Generated.',
                        timeout: 5000,
                        icon: 'ni ni-bell-55',
                        type: 'success',
                    });
                    this.isDisabled = false;
                })
            },
            filterClientAcceptanceReport(type)
            {
                const query_params = "?search=" + this.searchText + "&start_date=" + this.dates.start_date + "&end_date=" + this.dates.end_date + "&type=" + type;
                
                axios.get(apiDomain + 'data/initial_settlement_data.filter_client_accept' + query_params,{ headers: getHeader() })
                .then ( response => {
                    if (response.status === 200)
                    {
                        switch(type)
                        {
                            case 'project':
                                this.projectData = response.data;
                            break;

                            case 'ssa':
                                this.ssaData = response.data;   
                            break;

                            case 'sse':
                                this.sseData = response.data;   
                            break;

                            case 'agent':
                                this.agentData = response.data;   
                            break;
                        }
                    }
                })
                .catch ( error => {
                    this.errorFunction(error, "Get Data from API")
                });
            },
            async fetchClientAcceptanceSummaryData() {
                try {
                    const response1 = this.filterClientAcceptanceReport('project');
                    const response2 = this.filterClientAcceptanceReport('ssa');
                    const response3 = this.filterClientAcceptanceReport('sse');
                    const response4 = this.filterClientAcceptanceReport('agent');
                    
                    const [data1, data2, data3, data4] = await Promise.all([response1, response2, response3, response4]);

                    this.projectData = data1;
                    this.ssaData = data2;
                    this.sseData = data3;
                    this.agentData = data4;
                    // Handle the data as needed
                } catch (error) {
                    // Handle errors
                }
            }

        }
    }
</script>