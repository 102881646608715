<template>
  <b-card no-body>
    <b-card-header class="border-0">
      <b-row >
        <b-col cols="6">
          <h3 class="mb-0">Users</h3>
        </b-col>
        <b-col cols="6" class="text-right">
          <el-tooltip content="Add a new User" placement="top">
            <base-button type="neutral" icon size="sm" @click="addUser()">
              <span class="btn-inner--icon"><i class="fas fa-user-edit"></i></span>
              <span class="btn-inner--text">Add</span>
            </base-button>

          </el-tooltip>
        </b-col>
      </b-row>
    </b-card-header>

    <el-table class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="tableData">
      <el-table-column label="Id"
                       min-width="100px"
                       prop="id"
                       sortable>
        <template v-slot="{row}">
          <!--<b-img :src="row.image" rounded="circle" class="avatar mr-3" />-->
          <b>{{row.id}}</b>
        </template>
      </el-table-column>
      <el-table-column label="Username"
                       min-width="210px"
                       prop="name"
                       sortable>
        <template v-slot="{row}">
          <!--<b-img :src="row.image" rounded="circle" class="avatar mr-3" />-->
          <b>{{row.name}}</b>
        </template>
      </el-table-column>
      <el-table-column label="Email"
                       prop="email"
                       min-width="240px"
                       sortable>
      </el-table-column>

      <el-table-column label="Created At"
                       min-width="100px"
                       prop="created_at"
                       sortable>
        <template v-slot="{row}">
          <a href="#!" class="font-weight-bold">{{row.created_at}}</a>
        </template>
      </el-table-column>

      <el-table-column min-width="180px" align="center">
        <div slot-scope="{row}" class="table-actions">
          <el-tooltip content="Edit" placement="top">
            <a href="#!" @click.prevent="onEdit(row)" class="table-action" data-toggle="tooltip" data-original-title="Edit product">
              <i class="fas fa-user-edit"></i>
            </a>
          </el-tooltip>
          <el-tooltip content="Delete" placement="top">
            <a href="#!" @click.prevent="onDelete(row)" class="table-action table-action-delete" data-toggle="tooltip"
               data-original-title="Delete product">
              <i class="fas fa-trash"></i>
            </a>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
  </b-card>

</template>
<script>
  // import users from './users'
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Tooltip } from 'element-ui'
  import axios from 'axios'
  import {apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';

  export default {
    name: 'users-table',
    components: {
      [Tooltip.name]: Tooltip,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    mixins: [ErrorFunctionMixin],
    data() {
      return {
        tableData: null,
        currentPage: 1,
        mode: 'listing',
      };
    },
    mounted () {
      this.userList()
    },
    methods: {
      onEdit(row) {
        this.$router.push({path: '/setup/editUsers/' + row.id })
        // alert(`You want to edit ${row.name}`)
      },
      onDelete(row) {
        swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          // denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.delete(apiDomain + 'setup/user/' + row.id,{ headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {
                this.$notify({
                  message:
                    '<b>User : ' + row.name + '</b> - Successfully Deleted.',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                this.userList()
              }
            })
            .catch ( error => {
                console.log(error.response.status)
                this.errorFunction(error, "Delete User")
                
            });
          } 
        })
      },
      addUser(){
        this.$router.push({path: '/setup/addUsers' })
      },
      userList(){
        axios
        .get(apiDomain + 'setup/user', { headers: getHeader() })
        .then(response => (
          this.tableData = response.data.data
          ))
      console.log(this.info)   
      }

    }
  }
</script>
