<template>
    <card header-classes="bg-transparent" class="border border-white;">
        <b-row align-v="center" slot="header">
            &nbsp;&nbsp;<h5 class="h3 mb-0 ">Chart</h5>
        </b-row>
        <b-row>
            <b-col md="12" xl="12">
                <apexchart type="bar" height="400" :options="projectChartOptions" :series="projectSeries"></apexchart>
                <br><br>
                <apexchart type="pie" height="300" :options="ssaChartOptions" :series="ssaSeries"></apexchart>
                <br><br>
                <apexchart type="pie" height="300" :options="sseChartOptions" :series="sseSeries"></apexchart>
                <br><br>
                <apexchart type="bar" height="500" :options="agentChartOptions" :series="agentSeries"></apexchart>
            </b-col>
        </b-row>
    </card>
</template>
<script>
  import VueApexCharts from 'vue-apexcharts';
    export default {
        props: ['projectData', 'ssaData', 'sseData', 'agentData'],
        components: {
            "apexchart": VueApexCharts,
        },
        data() {
            return {
                projectSeries: [],
                projectChartOptions: {
                    title: {
                        text: 'Projection Property Sales via SPA',
                        align: 'center',
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold',
                        }
                    },
                },
                ssaSeries: [],
                ssaChartOptions: {
                    title: {
                        text: 'SSA Projection Sales via SPA',
                        align: 'center',
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold',
                        }
                    },
                },
                sseSeries: [],
                sseChartOptions: {
                    title: {
                        text: 'SSE Projection Sales via SPA',
                        align: 'center',
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold',
                        }
                    },
                },
                agentSeries: [],
                agentChartOptions: {
                    title: {
                        text: 'Projection Agent Sales via SPA',
                        align: 'center',
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold',
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 15,
                            horizontal: true,
                        }
                    },
                },
            }
        },
        watch: {
            projectData(){
                if(this.projectData && this.projectData.length > 0)
                {
                    this.projectChartOptions = {
                        xaxis: {
                            categories: this.projectData.map(item => item.prod_prop_name_1)
                        },
                    };

                    this.projectSeries = [{
                        data: this.projectData.map(item => item.project_total)
                    }]
                }
            },
            ssaData() {
                if(this.ssaData && this.ssaData.length > 0)
                {
                    console.log(this.ssaData);
                    this.ssaChartOptions = {
                        labels: this.ssaData.map(item => item.ssa_fullname + ' / ' +item.ssa_agent_id),
                        colors: this.generateRandomColors(this.ssaData.length),
                    };
                    this.ssaSeries = this.ssaData.map(item => item.project_total == null ? 0 : item.project_total);
                }
            },
            sseData() {
                if(this.sseData && this.sseData.length > 0)
                {
                    this.sseChartOptions = {
                        labels: this.sseData.map(item => item.sse_fullname + ' / ' +item.sse_agent_id),
                        colors: this.generateRandomColors(this.sseData.length),
                    };

                    this.sseSeries = this.sseData.map(item => item.total_client);
                }
            },
            agentData() {
                if(this.agentData && this.agentData.length > 0)
                {
                    this.agentChartOptions = {
                        xaxis: {
                            categories: this.agentData.map(item => item.agent_fullname)
                        },
                    };

                    this.agentSeries = [{
                        data: this.agentData.map(item => item.project_total)
                    }]
                } 
            }
        },
        methods: {
            generateRandomColors(length) {
                const colors = [];
                const letters = '0123456789ABCDEF';

                for (let i = 0; i < length; i++) {
                    let color = '#';
                    for (let j = 0; j < 6; j++) {
                    color += letters[Math.floor(Math.random() * 16)];
                    }
                    colors.push(color);
                }

                return colors;
            }
        },
        
    }
</script>