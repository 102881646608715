<template>
    <card header-classes="bg-transparent" class="border border-white;">
      <b-row align-v="center" slot="header">
      &nbsp;&nbsp;<h5 class="h3 mb-0 ">Performance Report for Bank Submission</h5>
      </b-row>
      <b-row>
        <b-col xl="12">
          <apexchart type="bar" height="150" :options="chartOptions" :series="series"></apexchart>
        </b-col>
      </b-row>
    </card>
</template>
<script>
  import { BaseProgress } from '@/components';
  import VueApexCharts from 'vue-apexcharts';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui';
  import { Modal } from '@/components';
  import { resolve } from 'url';
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios';
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import standardPaginationMix from '@/assets/js/standardPaginationMix.js'

  export default {
    props : [ "startDate", "endDate" ],
    watch: {
      startDate(){
        this.changeData()
      },

    },
    components: {
      Modal,
      BaseProgress,
      "apexchart": VueApexCharts,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    created()
    {
        this.changeData()
        // this.$watch(() => {
    
        //   // const str = JSON.stringify(this.chartData, null, 2);
        //   // console.log(str);
        //   this.series = 
        //   [ 
        //     {
        //       name: 'APPROVED',
        //       data: this.chartData.approved_count
        //     }, 
        //     {
        //       name: 'REJECT',
        //       data: this.chartData.reject_count
        //     }, 
        //   ]

        //   this.chartOptions.xaxis = {
        //     categories: this.chartData.bank_name,
        //   }
        //   //const str = JSON.stringify(this.chartOptions.xaxis.categories, null, 2);
        //   console.log(this.chartOptions.xaxis.categories);
         
        //   return {};
        // }, () => {
        //   // p1 or p2 changed
        // })
    },
    data() {
      return {
        modals: {
          formOverdueProject: false,
          formOverdueDocument: false,
        },
        series: 
        [ 
          {
            name: 'APPROVED',
            data: [],
          }, 
          {
            name: 'REJECT',
            data: [],
          }, 
        ],
        chartOptions: {
          colors: ['#2dce89', '#fb6340'],
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              
 
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: true,
            style: {
        colors: ['#333']
    },
             offsetY: 50,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            tickAmount: 10,
            title: {
              text: 'CASE'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " CASES"
              }
            }
          }
        },
      }
    },
    methods:
    {
      changeData()
      {
         axios.get(apiDomain + 'data/submission_data.details.by_date_range/' + this.startDate + '/' + this.endDate ,{ headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          const obj = response.data

          let bank_name = [];
          let approved_count = [];
          let reject_count = [];

          Object.keys(obj).forEach(key => {
            if (obj[key].approved_count > 0 && obj[key].reject_count > 0 )
            {
            bank_name.push(obj[key].bank_name)
            approved_count.push(obj[key].approved_count)
            reject_count.push(obj[key].reject_count)
            }
          })

          this.series = 
          [ 
            {
              name: 'APPROVED',
              data: approved_count
            }, 
            {
              name: 'REJECT',
              data: reject_count
            }, 
          ]

          // this.chartOptions.xaxis =  
          // {
          //   categories : ['MBSB', 'Public Bank', 'Affin Bank', 'Bank Simpanan', 'Bank Muamalat', 'CIMB Bank', 'Alliance Bank', 'UOB Bank', 'Bank Islam', 'Maybank', 'AIA'],
          // }
          this.chartOptions = {...this.chartOptions, ...{
              xaxis: {
                categories: bank_name
              }
          }}

          //this.chartOptions.xaxis.categories = ['MBSB', 'Public Bank', 'Affin Bank', 'Bank Simpanan', 'Bank Muamalat', 'CIMB Bank', 'Alliance Bank', 'UOB Bank', 'Bank Islam', 'Maybank', 'AIA'],
          // const str = JSON.stringify(this.chartOptions.xaxis.categories, null, 2);
          // console.log(str);
   
        }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Bank Data Submission")
        });


      },
    },
  }
</script>
<style>
</style>
